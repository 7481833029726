import React, { useEffect } from 'react';
import { Row, Col, Card } from 'antd';
import AppIcon from './AppIcon';
import styles from './auth-hoc.scss';
const AuthHOC = ({ children }) => {
    useEffect(() => {
        const HtmlNode = document.getElementsByTagName('html')[0];
        HtmlNode.setAttribute('scroll', 'hide');
        return () => {
            HtmlNode.removeAttribute('scroll');
        };
    }, []);
    return (React.createElement("section", { className: styles.wrapper },
        React.createElement(Row, null,
            React.createElement(Card, { className: styles.custome_card },
                React.createElement(AppIcon, null),
                React.createElement(Col, null, children)))));
};
AuthHOC.displayName = 'AuthHOC';
AuthHOC.defaultProps = {
    children: React.createElement(React.Fragment, null, "Not found")
};
export default AuthHOC;
