import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
// import categoryReducer from './categoryReducer';
import confirmModalReducer from './confirmModalReducer';
import deleteModalReducer from './deleteModalReducer';
import companyDataReducer from './companyDataReducer';
import profileReducer from './profileReducer';
const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    profileData: profileReducer,
    successModal: confirmModalReducer,
    deleteModal: deleteModalReducer,
    companyData: companyDataReducer
});
export default rootReducer;
