import { createReducer } from 'typesafe-actions';
import { SET_CONFIRM_MODAL_CLOSE, SET_CONFIRM_MODAL_ERROR, SET_CONFIRM_MODAL_OPEN } from '../../constants/modalConstants';
const initialState = {
    loading: false,
    error: null,
    visible: false,
    data: null
};
const confirmModalReducer = createReducer(Object.assign({}, initialState)).handleType(SET_CONFIRM_MODAL_OPEN, (state, action) => (Object.assign(Object.assign({}, state), { visible: true, data: action.payload }))).handleType(SET_CONFIRM_MODAL_CLOSE, state => (Object.assign(Object.assign({}, state), { error: null, visible: false, data: null }))).handleType(SET_CONFIRM_MODAL_ERROR, (state, action) => (Object.assign(Object.assign({}, state), { error: action.payload })));
export default confirmModalReducer;
