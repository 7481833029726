import { __awaiter } from "tslib";
import { USERS, WALLET } from '../constants/Urls';
import { http } from './api';
export const fetchWalletUsers = (dispatch, paginated) => __awaiter(void 0, void 0, void 0, function* () {
    return http.get(`${USERS}?payrollTypeB=true${paginated ? `?skip=${paginated.skip}&limit=${paginated.limit}` : ''}`);
});
export const addCreditAPI = (dispatch, data) => __awaiter(void 0, void 0, void 0, function* () { return http.post(`${WALLET}/addCredit`, data); });
export const fetchWalletLogs = (dispatch, paginated, date) => __awaiter(void 0, void 0, void 0, function* () {
    return http.get(`${WALLET}/log/filter/${date === null || date === void 0 ? void 0 : date.startDate}/${date === null || date === void 0 ? void 0 : date.endDate}`);
});
