import { __awaiter } from "tslib";
import { BRANCHES, BRANCH_USERS } from '../constants/Urls';
import { http } from './api';
// export const fetchBranches = async (id: string):Promise<unknown> => {
// 	const response = await http.get(`${BRANCHES}/${id}`)
// 		.then(handleResponse).catch(errors => ({ errors }));
// 	if (response?.errors) {
// 		return ({
// 			type: SET_BRANCHES_ERROR,
// 			payload: { errors: response?.errors }
// 		});
// 	}
// 	return {
// 		type: SET_BRANCHES,
// 		payload: response
// 	};
// };
export const fetchAllBranchs = (dispatch, paginated) => __awaiter(void 0, void 0, void 0, function* () {
    return http.get(`${BRANCHES}${paginated ? `?skip=${paginated.skip}&limit=${paginated.limit}` : ''}`);
});
export const fetchBranch = (dispatch, paginated, id) => __awaiter(void 0, void 0, void 0, function* () {
    return http.get(`${BRANCHES}/${id}${paginated ? `?skip=${paginated.skip}&limit=${paginated.limit}` : ''}`);
});
export const fetchBranchBoxes = (dispatch, paginated, id) => __awaiter(void 0, void 0, void 0, function* () {
    return http.get(`${BRANCHES}/boxbranch/${id}${paginated ? `?skip=${paginated.skip}&limit=${paginated.limit}` : ''}`);
});
export const createBranch = (dispatch, data) => __awaiter(void 0, void 0, void 0, function* () { return http.post(`${BRANCHES}`, data); });
export const updateBranch = (dispatch, data, id) => __awaiter(void 0, void 0, void 0, function* () { return http.put(`${BRANCHES}/${id}`, data); });
export const fetchBranchUsers = (dispatch, paginated, branchId) => __awaiter(void 0, void 0, void 0, function* () {
    return http.get(`${BRANCH_USERS}/${branchId}${paginated ? `?skip=${paginated.skip}&limit=${paginated.limit}` : ''}`);
});
export const getBranchFutureOrdersAPI = (dispatch, data) => __awaiter(void 0, void 0, void 0, function* () { return http.put(`${BRANCHES}/orders/future`, Object.assign({}, data)); });
export const deleteBranchAPI = (dispatch, data) => __awaiter(void 0, void 0, void 0, function* () { return http.delete(`${BRANCHES}/soft/delete`, { data }); });
