import { createReducer } from 'typesafe-actions';
import { SET_DELETE_MODAL_OPEN, SET_DELETE_MODAL_CLOSE } from '../../constants/modalConstants';
const initialState = {
    loading: false,
    visible: false,
    error: null,
    data: null
};
const confirmModalReducer = createReducer(Object.assign({}, initialState)).handleType(SET_DELETE_MODAL_OPEN, (state, action) => (Object.assign(Object.assign({}, state), { visible: true, data: action.payload }))).handleType(SET_DELETE_MODAL_CLOSE, state => (Object.assign(Object.assign({}, state), { error: null, visible: false, data: null })));
export default confirmModalReducer;
