import { __awaiter } from "tslib";
import { COMPANIES } from '../constants/Urls';
import { http } from './api';
// export const fetchCompanies = async ():Promise<unknown> => {
// 	const response = await http.get(COMPANIES)
// 		.then(handleResponse).catch(errors => ({ errors }));
// 	if (response?.errors) {
// 		return ({
// 			type: SET_COMPANIES_ERROR,
// 			payload: { errors: response?.errors }
// 		});
// 	}
// 	return {
// 		type: SET_COMPANIES,
// 		payload: response
// 	};
// };
export const fetchCompanies = (dispatch, paginated) => __awaiter(void 0, void 0, void 0, function* () {
    return http.get(`${COMPANIES}${paginated ? `?skip=${paginated.skip}&limit=${paginated.limit}` : ''}`);
});
export const addCompanyApi = (dispatch, data) => __awaiter(void 0, void 0, void 0, function* () { return http.post(COMPANIES, data); });
export const updateCompanyApi = (dispatch, data, id) => __awaiter(void 0, void 0, void 0, function* () { return http.put(`${COMPANIES}/${id}`, data); });
