import { UserOutlined } from '@ant-design/icons';
import useCustomQuery from '@src/hooks/useCustomQuery';
import { logoutApi } from '@src/shared/services/auth-service';
import { Avatar, Breadcrumb, Button, Dropdown, Space, Typography } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './page-hoc.scss';
const HeaderLayout = ({ profileData, isError = false }) => {
    const history = useHistory();
    const allPaths = history.location.pathname.split('/');
    const { refetch: handleLogout } = useCustomQuery(['logout'], logoutApi, undefined, {
        enabled: false,
        onSuccess: () => {
            history.replace('/login');
        }
    });
    useEffect(() => {
        if (isError) {
            history.replace('/login');
        }
    }, [isError, history]);
    const items = [
        {
            key: '1',
            label: (React.createElement(Button, { onClick: () => handleLogout(), type: 'text' },
                React.createElement(Text, { type: 'danger' }, "Logout")))
        }
    ];
    return (React.createElement("header", { className: styles.app_header },
        React.createElement(Breadcrumb, { separator: '/' }, allPaths.map(path => (React.createElement(Breadcrumb.Item, { key: path }, path)))),
        React.createElement(Dropdown, { menu: { items } },
            React.createElement(Space, null,
                React.createElement(Avatar, { icon: React.createElement(UserOutlined, null) }),
                React.createElement(Typography.Text, { style: {
                        textTransform: 'lowercase'
                    } }, profileData.email)))));
};
export default HeaderLayout;
