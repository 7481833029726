import { createEpicMiddleware } from 'redux-observable';
import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';
import rootReducer from './reducers';
// import rootEpic from './epics';
import services from '../services';
import { composeEnhancers } from './util';
import history from '../utils/history';
const epicMiddleware = createEpicMiddleware({
    dependencies: services
});
const routerMiddleware = createRouterMiddleware(history);
function configureStore(initialState) {
    // configure middlewares
    const middlewares = [epicMiddleware, routerMiddleware];
    // compose enhancers
    const enhancer = composeEnhancers(applyMiddleware(...middlewares));
    // create store
    return createStore(rootReducer(history), initialState, enhancer);
}
export const store = configureStore();
// epicMiddleware.run(rootEpic);
// export store singleton instance
export default { store };
