import { SET_COMPANY_DATA } from '@src/shared/constants/fetchConstants';
import { createReducer } from 'typesafe-actions';
const initialState = {
    id: '',
    name: '',
    address: '',
    country: '',
    branches: []
};
const companyDataReducer = createReducer(Object.assign({}, initialState)).handleType(SET_COMPANY_DATA, (state, action) => (Object.assign(Object.assign({}, state), action.payload)));
export default companyDataReducer;
