import { TIME_MULTIPLIER } from '@src/constants';
import moment from 'moment';
const localCurr = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 });
export const intersect = (array1, array2) => array1 === null || array1 === void 0 ? void 0 : array1.filter(value => array2.includes(value));
export const stringCompare = (a, b) => {
    if (typeof (a) === 'string' && typeof (b) === 'string')
        return a.toLowerCase() < b.toLowerCase() ? -1 : a.toLowerCase() > b.toLowerCase() ? 1 : 0;
    return a < b ? -1 : a > b ? 1 : 0;
};
export const dateCompare = (a, b) => moment(a).diff(moment(b));
export const generateColorFromValue = (progress) => {
    let color = '#D9D9D9';
    if (progress >= 0.3)
        color = '#F47B50';
    if (progress >= 0.5)
        color = '#FBE050';
    if (progress >= 0.7)
        color = '#B6E59F';
    if (progress >= 0.8)
        color = '#3B9665';
    return color;
};
export const getCost = (price, quantity, unit) => {
    const measure = (unit === null || unit === void 0 ? void 0 : unit.label) ? unit.label : unit;
    let cost = 0;
    if (measure === 'gr' || measure === 'ml') {
        cost = Number(parseFloat(`${(price * 1000) / quantity}`).toFixed(2));
    }
    else if (measure === 'kg' || measure === 'liter' || measure === 'ea') {
        cost = Number(parseFloat(`${(price / quantity)}`).toFixed(2));
    }
    return cost;
};
export const getPrice = (cost, quantity) => Number(parseFloat(`${cost * quantity}`).toFixed(2));
export const currFormat = (money = 0) => localCurr.format(money);
export const getAvgCost = (rate = 0, unit = '') => {
    let cost = 0;
    if (unit === 'gr' || unit === 'ml') {
        cost = Number(parseFloat(`${rate * 1000}`).toFixed(2));
    }
    else if (unit === 'kg' || unit === 'liter' || unit === 'ea') {
        cost = Number(parseFloat(`${rate}`).toFixed(2));
    }
    return cost;
};
export const getUnit = (unit) => {
    const measure = (unit === null || unit === void 0 ? void 0 : unit.label) ? unit.label : unit;
    if (measure === 'gr' || measure === 'kg') {
        return 'kg';
    }
    if (measure === 'ml' || measure === 'liter') {
        return 'liter';
    }
    return 'ea';
};
export const getUnitCostForRecipeIngredient = (ri) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    let unit = 'gr';
    let cost = 0;
    if ((_b = (_a = ri === null || ri === void 0 ? void 0 : ri.ingredient) === null || _a === void 0 ? void 0 : _a.inventory) === null || _b === void 0 ? void 0 : _b.length) {
        unit = (_f = (_e = (_d = (_c = ri === null || ri === void 0 ? void 0 : ri.ingredient) === null || _c === void 0 ? void 0 : _c.inventory) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.measure) === null || _f === void 0 ? void 0 : _f.unit;
        cost = ((_j = (_h = (_g = ri === null || ri === void 0 ? void 0 : ri.ingredient) === null || _g === void 0 ? void 0 : _g.inventory) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.rate) * ((ri === null || ri === void 0 ? void 0 : ri.amount) || 0);
        if (unit === 'kg' || unit === 'liter') {
            cost /= 1000;
        }
        if (unit === 'kg') {
            unit = 'gr';
        }
        if (unit === 'liter') {
            unit = 'ml';
        }
        cost = +cost.toFixed(3);
    }
    else if ((_l = (_k = ri === null || ri === void 0 ? void 0 : ri.subRecipe) === null || _k === void 0 ? void 0 : _k.ingredients) === null || _l === void 0 ? void 0 : _l.length) {
        ri.subRecipe.ingredients.forEach((rei) => {
            const { cost: subReCost } = getUnitCostForRecipeIngredient(rei);
            cost += subReCost;
        });
        if ((_m = ri === null || ri === void 0 ? void 0 : ri.subRecipe) === null || _m === void 0 ? void 0 : _m.portion) {
            cost = (ri.amount * cost) / +ri.subRecipe.portion;
            cost = +cost.toFixed(3);
        }
    }
    return ({ unit, cost });
};
export const searchSubstring = (searchWith, searchFrom) => {
    if (searchFrom) {
        if (typeof searchFrom === 'string') {
            return !!searchFrom.toLowerCase().includes(searchWith.toLowerCase());
        }
        if (typeof searchFrom === 'number') {
            return searchFrom === Number(searchWith);
        }
    }
    return false;
};
export const totalCalaries = (nutri) => {
    const fat = Number(nutri.fat) || 0;
    const protein = Number(nutri.protein) || 0;
    const carbohydrates = Number(nutri.carbohydrates) || 0;
    return ((fat * 9) + (protein * 4) + (carbohydrates * 4));
};
export const getUTCNow = (date) => {
    const offset = date.getTimezoneOffset() * TIME_MULTIPLIER;
    return date.getTime() - offset;
};
export const getLocalNow = (date) => {
    const offset = -date.getTimezoneOffset() * TIME_MULTIPLIER;
    return date.getTime() - offset;
};
export const hasPermission = (userRole, AllowedRoles) => {
    if (AllowedRoles === null || AllowedRoles === void 0 ? void 0 : AllowedRoles.includes(userRole)) {
        return true;
    }
    return false;
};
export function batchArrayIntoChunks(array, batchSize) {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += batchSize) {
        chunkedArray.push(array.slice(i, i + batchSize));
    }
    return chunkedArray;
}
/* eslint-disable */
export const getRegEx = type => {
    const regExObj = {
        email: /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+?))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/,
        userName: /^[a-zA-Z]{2,}[a-zA-Z0-9_\s]*[a-zA-Z0-9]$/
    };
    const regEx = regExObj[`${type}`];
    return regEx;
};
/* eslint-enable */
