export const SET_CONFIRM_MODAL_OPEN = 'modal/SUCCESS_MODAL_OPEN';
export const SET_CONFIRM_MODAL_CLOSE = 'modal/SUCCESS_MODAL_CLOSE';
export const SET_CONFIRM_MODAL_ERROR = 'modal/SUCCESS_MODAL_ERROR';
export const SET_DELETE_MODAL_OPEN = 'modal/DELETE_MODAL_OPEN';
export const SET_DELETE_MODAL_CLOSE = 'modal/DELETE_MODAL_CLOSE';
export var WalletLogType;
(function (WalletLogType) {
    WalletLogType["TEST"] = "test";
    WalletLogType["VOUCHER"] = "voucher";
    WalletLogType["REFUND"] = "refund";
    WalletLogType["WALLET_WITHDRAWAL"] = "wallet_withdrawal";
    WalletLogType["ALLOWANCE"] = "allowance";
    WalletLogType["ORDER_PLACED"] = "order_placed";
    WalletLogType["ORDER_CANCELLED"] = "order_cancelled";
    WalletLogType["PAYPAL_UPLOAD"] = "paypal_upload";
    WalletLogType["CREDIT_CARD_UPLOAD"] = "credit_card_upload";
    WalletLogType["USER_SOFT_DELETED"] = "user_soft_deleted";
    WalletLogType["USER_RESTORED"] = "user_restored";
})(WalletLogType || (WalletLogType = {}));
export const walletLogTypeList = [
    {
        value: 'allowance',
        label: 'Allowance',
        hoverText: 'Depositing allowance money via wallet'
    },
    {
        value: 'credit_card_upload',
        label: 'Credit Card Upload',
        hoverText: 'Manually Adding wallet credit just in the cases when Credit Card upload doesn\'t work after Credit Card proof of payment is provided by the user.'
    },
    {
        value: 'paypal_upload',
        label: 'PayPal Upload',
        hoverText: 'Manually adding wallet credit just in the cases when paypal upload doesn\'t work after paypal proof of payment is provided by the user.'
    },
    {
        value: 'refund',
        label: 'Refund',
        hoverText: 'Refund processed back to the user\'s wallet.'
    },
    {
        value: 'test',
        label: 'Test',
        hoverText: 'For internal use to test wallet functionalities.'
    },
    {
        value: 'voucher',
        label: 'Voucher',
        hoverText: 'Credits or free money added as promotional gifts or vouchers.'
    },
    {
        value: 'wallet_withdrawal',
        label: 'Wallet Withdrawal',
        hoverText: 'Elimination of user wallet credit after bank or paypal refund after withdraw request'
    }
];
