import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (name, api, paginated, options) => {
    const dispatch = useDispatch();
    return useQuery(name, () => api(dispatch, paginated, name[1]), options);
};
export function matchCustomQuery(queryResult, match) {
    switch (queryResult.status) {
        case 'loading':
            return match.whenLoading();
        case 'error':
            return match.whenError(queryResult.error);
        case 'success':
            return match.whenSuccessful(queryResult.data);
        case 'idle':
        default:
            return match.whenIdle();
    }
}
export function mapCustomQueryOrElse(queryResult, mapFn, defaultValue) {
    return matchCustomQuery(queryResult, {
        whenSuccessful: data => mapFn(data),
        whenLoading: () => defaultValue,
        whenError: () => defaultValue,
        whenIdle: () => defaultValue
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mergeCustomQueries(queries) {
    if (Object.values(queries).some(query => !query.isSuccess)) {
        return null;
    }
    return Object.entries(queries)
        .reduce((result, [key, query]) => {
        // @ts-expect-error mapping the object of successful queries to their data
        // eslint-disable-next-line security/detect-object-injection
        result[key] = query.data;
        return result;
    }, {});
}
