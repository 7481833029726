import { __awaiter } from "tslib";
import { COMPANY_EMPLOYEES, USERS } from '../constants/Urls';
import { http } from './api';
// export const fetchUsers = async ():Promise<unknown> => {
// 	const response = await http.get(USERS)
// 		.then(handleResponse).catch(errors => ({ errors }));
// 	if (response?.errors) {
// 		return ({
// 			type: SET_USERS_ERROR,
// 			payload: { errors: response?.errors }
// 		});
// 	} 
// 	return {
// 		type: SET_USERS,
// 		payload: response
// 	};
// };
export const fetchRoles = (dispatch) => __awaiter(void 0, void 0, void 0, function* () { return http.get(`${USERS}/roles`); });
export function fetchUsers(data) {
    return __awaiter(this, void 0, void 0, function* () {
        return http.post(`${USERS}`, data);
    });
}
export const fetchEmployees = (data) => __awaiter(void 0, void 0, void 0, function* () { return http.post(`${COMPANY_EMPLOYEES}`, data); });
export const fetchEmployeeDetails = (employeeId) => __awaiter(void 0, void 0, void 0, function* () { return http.get(`${COMPANY_EMPLOYEES}/${employeeId}`); });
export const fetchInternalUsers = (dispatch, paginated) => __awaiter(void 0, void 0, void 0, function* () { return http.get(`${USERS}?internalUsers=true${paginated ? `?skip=${paginated.skip}&limit=${paginated.limit}` : ''}`); });
export const fetchUserDetals = (dispatch, paginated, userId) => __awaiter(void 0, void 0, void 0, function* () {
    return http.get(`${USERS}/${userId}${paginated ? `?skip=${paginated.skip}&limit=${paginated.limit}` : ''}`);
});
export const addUsersAPI = (dispatch, data) => __awaiter(void 0, void 0, void 0, function* () { return http.post(`${USERS}/branch/${data === null || data === void 0 ? void 0 : data.branch}`, data); });
export const createEmployees = (data) => __awaiter(void 0, void 0, void 0, function* () { return http.post(`${COMPANY_EMPLOYEES}/create`, data); });
export const addBulkUsersAPI = (dispatch, data, id) => __awaiter(void 0, void 0, void 0, function* () { return http.post(`${USERS}/bulk/branch/${id}`, data); });
export const addBulkUsersWithMultipleBranchesAPI = (dispatch, data) => __awaiter(void 0, void 0, void 0, function* () { return http.post(`${USERS}/bulk/branch`, data); });
export const updateUsersAPI = (dispatch, data, id) => __awaiter(void 0, void 0, void 0, function* () { return http.put(`${USERS}/${id}`, data); });
export const updateEmployee = (data, id) => __awaiter(void 0, void 0, void 0, function* () { return http.put(`${COMPANY_EMPLOYEES}/${id}`, data); });
export const deleteEmployee = (id) => __awaiter(void 0, void 0, void 0, function* () { return http.delete(`${COMPANY_EMPLOYEES}/${id}`); });
export const deleteUsersAPI = (dispatch, data) => __awaiter(void 0, void 0, void 0, function* () {
    return http
        .delete(`${USERS}/soft/delete`, { data });
});
export const restoreUsersAPI = (dispatch, data) => __awaiter(void 0, void 0, void 0, function* () {
    return http
        .put(`${USERS}/restore/deleted`, Object.assign({}, data));
});
export const getUsersFutureOrdersAPI = (dispatch, data) => __awaiter(void 0, void 0, void 0, function* () {
    return http
        .put(`${USERS}/orders/future`, Object.assign({}, data));
});
