import { __awaiter } from "tslib";
import { LOGIN, LOGOUT, SEND_VERIFICATION_EMAIL, USER_PROFILE, VERIFY_EMAILS } from '../constants/Urls';
import { http } from './api';
// export const loginApi = async (payload:Auth):Promise<any> => {
// 	const response = await http.post(`${LOGIN}/null/1.1.0`, payload)
// 		.then(handleResponse).catch(errors => ({ errors }));
// 	if (response?.errors) {
// 		return {
// 			type: AUTH_RESPONSE_ERROR,
// 			payload: { errors: response.errors }
// 		};
// 	} 
// 	return {
// 		type: AUTH_RESPONSE,
// 		payload: response
// 	};
// };
export const loginApi = (dispatch, data) => __awaiter(void 0, void 0, void 0, function* () {
    return http
        .post(`${LOGIN}`, data);
});
export const logoutApi = () => __awaiter(void 0, void 0, void 0, function* () { return http.get(`${LOGOUT}`); });
export const getUserProfile = () => __awaiter(void 0, void 0, void 0, function* () { return http.get(`${USER_PROFILE}`); });
export const sendVerificationEmailAPI = (data) => __awaiter(void 0, void 0, void 0, function* () { return http.post(`${SEND_VERIFICATION_EMAIL}`, data); });
export const verifyEmailAPI = (data) => __awaiter(void 0, void 0, void 0, function* () { return http.post(`${VERIFY_EMAILS}`, data); });
