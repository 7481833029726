import { __awaiter } from "tslib";
import { FETCH_MEASURES } from '../constants/Urls';
import { http } from './api';
export const fetchMeasures = (dispatch, paginated) => __awaiter(void 0, void 0, void 0, function* () {
    return http
        .get(`${FETCH_MEASURES}${paginated ? `?skip=${paginated.skip}&limit=${paginated.limit}` : ''}`);
});
export const createMeasures = (dispatch, data) => __awaiter(void 0, void 0, void 0, function* () {
    return http
        .post(`${FETCH_MEASURES}`, data);
});
export const updateMeasures = (dispatch, data, id) => __awaiter(void 0, void 0, void 0, function* () {
    return http
        .put(`${FETCH_MEASURES}/${id}`, data);
});
export const deleteMeasures = (dispatch, data) => __awaiter(void 0, void 0, void 0, function* () {
    return http
        .delete(`${FETCH_MEASURES}`, { data });
});
