import { __awaiter } from "tslib";
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable new-cap */
import axios from 'axios';
import { message } from 'antd';
const { API_ENDPOINT } = process.env;
export const http = axios.create({
    baseURL: API_ENDPOINT,
    withCredentials: true
});
http.defaults.headers.post['Content-Type'] = 'application/json';
http.interceptors.response.use((response) => __awaiter(void 0, void 0, void 0, function* () {
    if ((response === null || response === void 0 ? void 0 : response.status) >= 200 && (response === null || response === void 0 ? void 0 : response.status) < 300) {
        return response.data;
    }
}), (error) => {
    var _a, _b;
    const { response } = error;
    const errors = {
        message: ((_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) || (error === null || error === void 0 ? void 0 : error.message) || 'Unexpected Error'
    };
    if ((response === null || response === void 0 ? void 0 : response.status) === 401 || (response === null || response === void 0 ? void 0 : response.status) === 403) {
        window.location.assign('/login');
    }
    else if ((response === null || response === void 0 ? void 0 : response.status) !== 500) {
        message.error(errors.message);
    }
    return Promise.reject(errors);
});
