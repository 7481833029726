import * as logger from './logger-service';
import * as localStorage from './local-storage-service';
import * as authService from './auth-service';
import * as ingredientsService from './ingredients-service';
import * as companyService from './company-service';
import * as branchService from './branch-service';
import * as userService from './user-service';
import * as measureService from './measure-service';
import * as supplierService from './supplier-service';
import * as categoryService from './category-service';
import * as walletService from './wallet-service';
export default {
    logger,
    localStorage,
    authService,
    ingredientsService,
    companyService,
    branchService,
    userService,
    measureService,
    supplierService,
    categoryService,
    walletService
};
