import { __awaiter } from "tslib";
import { FETCH_INGREDIENTS } from '../constants/Urls';
import { http } from './api';
export const fetchIngredients = (dispatch, paginated) => __awaiter(void 0, void 0, void 0, function* () {
    return http
        .get(`${FETCH_INGREDIENTS}${paginated ? `?skip=${paginated.skip}&limit=${paginated.limit}` : ''}`);
});
export const fetchIngredientsAndSubRecipes = (dispatch, paginated) => __awaiter(void 0, void 0, void 0, function* () {
    return http
        .get(`${FETCH_INGREDIENTS}/all${paginated ? `?skip=${paginated.skip}&limit=${paginated.limit}` : ''}`);
});
export const createIngredient = (dispatch, data) => __awaiter(void 0, void 0, void 0, function* () {
    return http
        .post(`${FETCH_INGREDIENTS}`, data);
});
export const updateIngredient = (dispatch, data, id) => __awaiter(void 0, void 0, void 0, function* () {
    return http
        .put(`${FETCH_INGREDIENTS}/${id}`, data);
});
export const deleteIngredient = (dispatch, data) => __awaiter(void 0, void 0, void 0, function* () { return http.delete(`${FETCH_INGREDIENTS}`, { data }); });
