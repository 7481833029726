import { __rest } from "tslib";
import React from 'react';
import { BrowserRouter as Router, Switch, Route, 
// Link
Redirect } from 'react-router-dom';
import './assets/scss/main.scss';
import './app.scss';
import AuthHOC from '@components/layout/AuthHOC';
import { Typography } from 'antd';
import routes, { LayoutMode } from './routes';
import { hasPermission, intersect } from './shared/utils/commonUtils';
import HeaderLayout from './components/layout/PageLayoutHOC/HeaderLayout';
import useCustomGlobalQuery from './hooks/useCustomGlobalQuery';
import { getUserProfile } from './shared/services/auth-service';
import { setUserProfileAC } from './shared/store/actions/profile';
const { Title } = Typography;
const tempRole = ['ADMIN'];
const hasRole = (role) => !!intersect(tempRole, role).length;
const AuthorizedRouteHOC = ({ children, allowedRoles, title }) => {
    var _a, _b, _c;
    const { data: profileData, isError } = useCustomGlobalQuery(['profile'], getUserProfile, setUserProfileAC, 'profileData');
    if (allowedRoles && hasPermission((_c = (_b = (_a = profileData === null || profileData === void 0 ? void 0 : profileData.data) === null || _a === void 0 ? void 0 : _a.role) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c.toUpperCase(), allowedRoles)) {
        return (React.createElement(React.Fragment, null,
            React.createElement(HeaderLayout, { profileData: profileData.data, isError: isError }),
            React.createElement(Title, { level: 3 }, title),
            children));
    }
    return (React.createElement("div", null, "Permision denied"));
};
const renderWithSubRoutes = (routList) => (React.createElement(Switch, null, routList === null || routList === void 0 ? void 0 : routList.map((_a) => {
    var { path, layoutMode, component: Component, title, id, role, routes: chiledRoutes } = _a, rest = __rest(_a, ["path", "layoutMode", "component", "title", "id", "role", "routes"]);
    return (React.createElement(Route, Object.assign({ path: path, key: id }, rest),
        React.createElement(AuthorizedRouteHOC, { allowedRoles: role, title: title }, (chiledRoutes === null || chiledRoutes === void 0 ? void 0 : chiledRoutes.length) ? (React.createElement(Component, null,
            React.createElement(Switch, null,
                React.createElement(React.Fragment, null, renderWithSubRoutes(chiledRoutes))))) : React.createElement(Component, null))));
})));
const App = () => (React.createElement("div", null,
    React.createElement(Router, null,
        React.createElement(Switch, null, routes.map((_a) => {
            var { path, layoutMode, component: Component, title, id, role, routes: chiledRoutes } = _a, rest = __rest(_a, ["path", "layoutMode", "component", "title", "id", "role", "routes"]);
            return (React.createElement(Route, Object.assign({ path: path, key: id }, rest),
                layoutMode === LayoutMode.auth && (React.createElement(AuthHOC, null,
                    React.createElement(Component, null))),
                layoutMode === LayoutMode.panel && (React.createElement(Component, null,
                    React.createElement(React.Fragment, null,
                        path === '' && React.createElement(Redirect, { to: '/dashboard/branch/reports' }),
                        role && hasRole(role) ? (chiledRoutes && renderWithSubRoutes(chiledRoutes)) : React.createElement("div", null, " Permision denied"))))));
        })))));
export default App;
