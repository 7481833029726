import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (name, api, actionCreator, reducerKey, paginated, options) => {
    const dispatch = useDispatch();
    const globalProfileData = useSelector((st) => st[`${reducerKey}`]);
    const qD = useQuery(name, () => api(dispatch, paginated, name[1]), Object.assign({ enabled: !globalProfileData, onSuccess: result => {
            // console.log('result', result);
            actionCreator(result.data);
        } }, options));
    return Object.assign(Object.assign({}, qD), { data: globalProfileData ? { data: globalProfileData } : qD.data });
};
