import { __awaiter } from "tslib";
import { FETCH_CATEGORIES, INGREDIENT_CATEGORIES } from '../constants/Urls';
import { http } from './api';
export const fetchCategory = (dispatch, paginated) => __awaiter(void 0, void 0, void 0, function* () {
    return http
        .get(`${FETCH_CATEGORIES}${paginated ? `?skip=${(paginated === null || paginated === void 0 ? void 0 : paginated.skip) || '0'}&limit=${paginated.limit}` : ''}`);
});
export const fetchIngredientCategory = (dispatch, paginated) => __awaiter(void 0, void 0, void 0, function* () {
    return http
        .get(`${INGREDIENT_CATEGORIES}${paginated ? `?skip=${(paginated === null || paginated === void 0 ? void 0 : paginated.skip) || '0'}&limit=${paginated.limit}` : ''}`);
});
