import loadable from '@loadable/component';
import { v4 as uuidv4 } from 'uuid';
const Menu = loadable(() => import(/* webpackChunkName: "menu" */ '@pages/panel/Kitchen/Menu'));
const BranchOrders = loadable(() => import(/* webpackChunkName: "BranchOrders" */ '@src/pages/panel/BranchOrders'));
const MenuDetails = loadable(() => import(/* webpackChunkName: "menudetails" */ '@pages/panel/Kitchen/MenuDetails'));
const AddonsGroup = loadable(() => import(/* webpackChunkName: "addonsgroup" */ '@pages/panel/Kitchen/AddonsGroup'));
const AddonsGroupDetails = loadable(() => import(/* webpackChunkName: "addonsgroupdetails" */ '@pages/panel/Kitchen/AddonsGroupDetails'));
const SignIn = loadable(() => import(/* webpackChunkName: "login" */ '@pages/Auth/Login'));
const KitchenLocations = loadable(() => import(/* webpackChunkName: "kitchenlocations" */ '@pages/panel/KitchenLocations'));
const Orders = loadable(() => import(/* webpackChunkName: "orders" */ '@pages/panel/Orders'));
const Companies = loadable(() => import(/* webpackChunkName: "companies" */ '@pages/panel/Companies'));
const Branches = loadable(() => import(/* webpackChunkName: "branches" */ '@pages/panel/Branches'));
const Employee = loadable(() => import(/* webpackChunkName: "employee" */ '@pages/panel/Employee'));
const Ingredients = loadable(() => import(/* webpackChunkName: "ingredients" */ '@pages/panel/Ingredients'));
const Preparations = loadable(() => import(/* webpackChunkName: "preparations" */ '@pages/panel/Preparations'));
const Dishes = loadable(() => import(/* webpackChunkName: "dishes" */ '@pages/panel/Dishes'));
const Utensils = loadable(() => import(/* webpackChunkName: "utensils" */ '@pages/panel/Utensils'));
const TiramizooOrders = loadable(() => import(/* webpackChunkName: "utensils" */ '@pages/panel/Tiramizoo'));
const WalletTransactions = loadable(() => import(/* webpackChunkName: "walletTransactions" */ '@pages/panel/WalletTransactions'));
const WalletUsers = loadable(() => import(/* webpackChunkName: "walletUsers" */ '@pages/panel/WalletUsers'));
const Discounts = loadable(() => import(/* webpackChunkName: "discounts" */ '@pages/panel/Discounts'));
const MarketingNotification = loadable(() => import(/* webpackChunkName: "marketingNotifications" */ '@pages/panel/Notifications/MarketingNotifications'));
const MarketingNotificationLogs = loadable(() => import(/* webpackChunkName: "marketingNotificationsLogs" */ '@pages/panel/Notifications/MarketingNotificationLogs'));
const OrderUpdateNotifications = loadable(() => import(/* webpackChunkName: "marketingNotificationsLogs" */ '@pages/panel/Notifications/OrderUpdateNotifications'));
const Suppliers = loadable(() => import(/* webpackChunkName: "utensils" */ '@pages/panel/Suppliers'));
const PageLayoutHOC = loadable(() => import(/* webpackChunkName: "wrapper" */ '@components/layout/PageLayoutHOC'));
const CompanyLevel = loadable(() => import(/* webpackChunkName: "CompanyLevel" */ '@src/pages/panel/CompanyLevel'));
const UserLevel = loadable(() => import(/* webpackChunkName: "UserLevel" */ '@src/pages/panel/UserLevel'));
const Ratings = loadable(() => import(/* webpackChunkName: "Ratings" */ '@src/pages/panel/Ratings'));
const DishDatabase = loadable(() => import(/* webpackChunkName: "DishDatabase" */ '@src/pages/panel/DishDatabase'));
const BranchReports = loadable(() => import(/* webpackChunkName: "BranchReports" */ '@src/pages/panel/BranchReports'));
const BranchGrowth = loadable(() => import(/* webpackChunkName: "BranchGrowth" */ '@src/pages/panel/BranchGrowth'));
const TiramizooSummary = loadable(() => import(/* webpackChunkName: "BranchGrowth" */ '@src/pages/panel/TiramizooSummary'));
const WalletSummary = loadable(() => import(/* webpackChunkName: "WalletSummary" */ '@src/pages/panel/WalletSummary'));
const Invoices = loadable(() => import(/* webpackChunkName: "Invoices" */ '@src/pages/panel/Invoices'));
const InvoicesBranchList = loadable(() => import(/* webpackChunkName: "Invoices" */ '@src/pages/panel/Invoices/BranchList'));
const CompanyBranches = loadable(() => import(/* webpackChunkName: "CompanyBranches" */ '@src/pages/panel/Company/Branches'));
const CompanyEmployees = loadable(() => import(/* webpackChunkName: "CompanyEmployees" */ '@src/pages/panel/Company/Employees'));
const BoxPickup = loadable(() => import(/* webpackChunkName: "BoxPickup" */ '@src/pages/panel/BoxPickup'));
export var LayoutMode;
(function (LayoutMode) {
    LayoutMode[LayoutMode["panel"] = 0] = "panel";
    LayoutMode[LayoutMode["auth"] = 1] = "auth";
})(LayoutMode || (LayoutMode = {}));
const routes = [
    {
        path: '/login',
        name: 'Login',
        component: SignIn,
        title: '',
        exact: true,
        layoutMode: LayoutMode.auth,
        id: uuidv4()
    },
    {
        path: '/',
        name: 'Panel',
        layoutMode: LayoutMode.panel,
        title: 'Dashboard',
        component: PageLayoutHOC,
        role: ['ADMIN', 'SALES', 'OPERATIONS'],
        id: uuidv4(),
        routes: [
            {
                path: '/dashboard/wallet-summary',
                name: 'Dashboard',
                title: 'Wallet Summary',
                component: WalletSummary,
                role: ['ADMIN', 'SALES', 'OPERATIONS'],
                id: uuidv4()
            },
            {
                path: '/dashboard/company-level',
                name: 'Dashboard',
                title: 'Company Level Dashboard',
                component: CompanyLevel,
                role: ['ADMIN', 'SALES', 'OPERATIONS'],
                id: uuidv4()
            },
            {
                path: '/dashboard/user-level',
                name: 'Dashboard',
                title: 'User Level Dashboard',
                component: UserLevel,
                role: ['ADMIN', 'SALES', 'OPERATIONS'],
                id: uuidv4()
            },
            {
                path: '/dashboard/ratings',
                name: 'Dashboard',
                title: 'Dish Ratings',
                component: Ratings,
                role: ['ADMIN', 'SALES', 'OPERATIONS'],
                id: uuidv4()
            },
            {
                path: '/dashboard/dish-database',
                name: 'Dashboard',
                title: 'Dish Database',
                component: DishDatabase,
                role: ['ADMIN', 'SALES', 'OPERATIONS'],
                id: uuidv4()
            },
            {
                path: '/dashboard/tiramizoo',
                name: 'Dashboard',
                title: 'Tiramizoo',
                component: TiramizooSummary,
                role: ['ADMIN', 'SALES', 'OPERATIONS'],
                id: uuidv4()
            },
            {
                path: '/dashboard/branch/growth',
                name: 'Dashboard',
                title: 'Branch Growth',
                component: BranchGrowth,
                role: ['ADMIN', 'SALES', 'OPERATIONS'],
                id: uuidv4()
            },
            {
                path: '/dashboard/branch/reports',
                name: 'Branch Reports',
                title: 'Branch Reports',
                component: BranchReports,
                role: ['ADMIN', 'SALES', 'OPERATIONS', 'BRANCH_MANAGER'],
                id: uuidv4()
            },
            {
                path: '/company/branches',
                name: 'Branches',
                title: 'Branches',
                component: CompanyBranches,
                role: ['ADMIN', 'SALES', 'OPERATIONS', 'BRANCH_MANAGER'],
                id: uuidv4()
            },
            {
                path: '/company/employees/:employeeId?',
                name: 'Employee',
                title: 'Employee',
                component: CompanyEmployees,
                role: ['ADMIN', 'SALES', 'OPERATIONS', 'BRANCH_MANAGER'],
                id: uuidv4()
            },
            {
                path: '/services/kitchen-locations/:kitchenId?',
                name: 'KitchenLocations',
                title: 'Kitchen Location',
                component: KitchenLocations,
                // exact: true,
                role: ['ADMIN', 'SALES'],
                id: uuidv4()
            },
            {
                path: '/services/orders',
                name: 'Orders',
                title: 'Orders',
                component: Orders,
                // exact: true,
                role: ['ADMIN', 'SALES'],
                id: uuidv4()
            },
            {
                path: '/services/discounts',
                name: 'Discounts',
                title: 'Discounts',
                component: Discounts,
                role: ['ADMIN', 'SALES'],
                id: uuidv4()
            },
            {
                path: '/services/wallet/users',
                name: 'Wallet Employees',
                title: 'Wallet Employees',
                exact: true,
                component: WalletUsers,
                role: ['ADMIN', 'SALES'],
                id: uuidv4()
            },
            {
                path: '/services/wallet/transactions',
                name: 'Wallet Transactions',
                title: 'Wallet Transactions',
                exact: true,
                component: WalletTransactions,
                role: ['ADMIN', 'SALES'],
                id: uuidv4()
            },
            {
                path: '/services/invoices',
                name: 'Invoices',
                title: 'Invoices',
                exact: true,
                component: Invoices,
                role: ['ADMIN'],
                id: uuidv4()
            },
            {
                path: '/services/invoices/:type',
                name: 'Invoices',
                title: 'Invoices Generation',
                exact: true,
                component: InvoicesBranchList,
                role: ['ADMIN'],
                id: uuidv4()
            },
            {
                path: '/services/box-pickup',
                name: 'Box Pickup',
                title: 'Box Pickup',
                exact: true,
                component: BoxPickup,
                role: ['ADMIN'],
                id: uuidv4()
            },
            {
                path: '/companies/:branches(branches)',
                name: 'Companies',
                title: 'Companies',
                exact: true,
                component: Companies,
                role: ['ADMIN', 'SALES'],
                id: uuidv4()
            },
            {
                path: '/companies/employees/:employeeId?',
                name: 'Employees',
                title: 'Employees',
                component: Employee,
                // exact: true,
                role: ['ADMIN', 'SALES'],
                id: uuidv4()
            },
            {
                path: '/companies/:companyId/:branchId?',
                name: 'Branch',
                title: 'Branches',
                component: Branches,
                exact: true,
                role: ['ADMIN', 'SALES'],
                id: uuidv4()
            },
            {
                path: '/kitchen/branch-orders',
                name: 'Branch Orders',
                title: 'Branch Orders',
                component: BranchOrders,
                exact: true,
                role: ['ADMIN', 'OPERATIONS', 'SALES'],
                id: uuidv4()
            },
            {
                path: '/kitchen/ingredients/:ingredientId?',
                name: 'Ingredients',
                title: 'Ingredients',
                component: Ingredients,
                // exact: true,
                role: ['ADMIN', 'OPERATIONS'],
                id: uuidv4()
            },
            {
                path: '/kitchen/preparations/:prepId?',
                name: 'Preparations',
                title: 'Preparations',
                component: Preparations,
                // exact: true,
                role: ['ADMIN', 'OPERATIONS'],
                id: uuidv4()
            },
            {
                path: '/kitchen/utensils/:utensilId?',
                name: 'Utensils',
                title: 'Utensils',
                component: Utensils,
                // exact: true,
                role: ['ADMIN', 'OPERATIONS'],
                id: uuidv4()
            },
            {
                path: '/kitchen/dishes/:dishId?',
                name: 'Dishes',
                title: 'Dishes',
                component: Dishes,
                // exact: true,
                role: ['ADMIN', 'OPERATIONS', 'SALES'],
                id: uuidv4()
            },
            {
                path: '/kitchen/menus',
                name: 'Menus',
                title: 'Menus',
                component: Menu,
                exact: true,
                role: ['ADMIN', 'OPERATIONS'],
                id: uuidv4()
            },
            {
                path: '/kitchen/menus/:menuId',
                name: 'Menus',
                title: 'Menus',
                component: MenuDetails,
                exact: true,
                role: ['ADMIN', 'OPERATIONS'],
                id: uuidv4()
            },
            {
                path: '/kitchen/addonsGroup',
                name: 'Addons Group',
                title: 'Addons Group',
                component: AddonsGroup,
                exact: true,
                role: ['ADMIN', 'OPERATIONS'],
                id: uuidv4()
            },
            {
                path: '/kitchen/addonsGroup/:addonGroupId',
                name: 'Addons Group',
                title: 'Addons Group',
                component: AddonsGroupDetails,
                exact: true,
                role: ['ADMIN', 'OPERATIONS'],
                id: uuidv4()
            },
            {
                path: '/users',
                name: 'Users',
                title: 'Users',
                exact: true,
                component: Employee,
                role: ['ADMIN'],
                id: uuidv4()
            },
            {
                path: '/tiramizoo',
                name: 'Tiramizoo Orders',
                title: 'Tiramizoo Orders',
                exact: true,
                component: TiramizooOrders,
                role: ['ADMIN', 'OPERATIONS'],
                id: uuidv4()
            },
            {
                path: '/notifications/marketing',
                name: 'Marketing Notifications',
                title: 'Marketing Notifications',
                component: MarketingNotification,
                role: ['ADMIN', 'SALES'],
                id: uuidv4()
            },
            {
                path: '/notifications/sent',
                name: 'Sent Notifications',
                title: 'Sent Notifications',
                component: MarketingNotificationLogs,
                role: ['ADMIN', 'SALES'],
                id: uuidv4()
            },
            {
                path: '/notifications/orderupdate',
                name: 'Order Update Notifications',
                title: 'Order Update Notifications',
                component: OrderUpdateNotifications,
                role: ['ADMIN', 'SALES'],
                id: uuidv4()
            },
            {
                path: '/suppliers',
                name: 'Suppliers',
                title: 'Suppliers',
                component: Suppliers,
                role: ['ADMIN', 'OPERATIONS'],
                id: uuidv4()
            }
        ]
    }
];
export default routes;
