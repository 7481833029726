export const LOGOUT = '/auth/signout';
export const USER_PROFILE = '/external/dashboard/me/profile';
export const LOGIN = 'auth/signin/internal/web/null/1.1.0';
export const FETCH_INGREDIENTS = '/admin/ingredients/v2';
export const COMPANIES = '/companies';
export const BRANCHES = '/branches';
export const BRANCH_USERS = '/branchusers';
export const USERS = '/users';
export const FETCH_MEASURES = '/admin/measures';
export const FETCH_SUPPLIERS = '/admin/suppliers';
export const FETCH_RECIPES = '/admin/recipe/v2';
export const FETCH_RECIPE_VARIANTS_MENU = '/admin/recipe/variants';
export const ADD_RECIPE_VARIANT_IMAGE = '/admin/recipe/variants/addImage';
export const FETCH_CATEGORIES = `/admin/category?type=CATEGORY`;
export const INGREDIENT_CATEGORIES = `/admin/category?type=INGREDIENT_TYPE`;
export const FETCH_NUTRITIONAL = '/admin/nutritional/info';
export const KITCHEN_LOCATION = '/kitchen';
export const KITCHEN_BRANCH_ORDERS = '/kitchen/branch-orders';
export const ORDERS = '/orders';
export const FETCH_MENU = '/admin/menu/v2';
export const FETCH_KITCHENS = '/kitchen';
export const FETCH_RECIPE_INGREDIENTS = '/admin/recipe/ingredient';
export const KITCHEN_RECIPE_VARIANTS = '/admin/kitchen/recipevariants';
export const FETCH_ALLERGENS = '/admin/allergen';
export const FETCH_ADDITIVES = '/admin/additive';
export const FETCH_ADDONS_GROUP = '/admin/addonsgroup';
export const DISCOUNT_URL = '/discounts';
export const WALLET = '/wallet';
export const NOTIFICATION = '/notification';
export const SEND_NOTIFICATION_TO_ALL_USERS = '/sendNotificationToAllUsers';
export const DASHBOARD = '/dashboard';
export const SEND_VERIFICATION_EMAIL = '/auth/verification/sendemail';
export const VERIFY_EMAILS = '/auth/confirm/emails';
export const DASHBOARD_BRANCH_GROWTH = '/dashboard/branch/growth';
export const DASHBOARD_BRANCH_BOX_PICKUP = '/service/box-pickup';
export const DASHBOARD_BRANCH_ORDERS_BREAKDOWN = '/external/dashboard/branch/orders/breakdown';
export const DASHBOARD_BRANCH_ORDERS_SUMMARY = '/external/dashboard/branch/orders/summary';
export const DASHBOARD_BRANCH_ORDERS_FREQUENCY = '/external/dashboard/branch/orders/frequency';
export const DASHBOARD_BRANCH_ORDERS = '/external/dashboard/branch/orders';
export const DASHBOARD_BRANCH_DELIVERY_SUMMARY = '/external/dashboard/branch/delivery';
export const DASHBOARD_BRANCH_ASSIGNED = '/external/dashboard/branches-assigned';
export const DASHBOARD_KITCHEN_ASSIGNED = '/external/dashboard/kitchens-assigned';
export const COMPANY_EMPLOYEES = '/external/company/employees';
export const ERP = '/erp';
